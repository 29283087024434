import React, { useMemo } from 'react'
import StarRating from '@pixum/rating'
import ExternalImage from '../generic/ExternalImage'
import '../../styles/featured_image.css'
import { useTranslation } from 'react-i18next'
import CTAButton from '../generic/CTAButton'
import { Typography } from '@mui/material'
import { Rating } from '../../types'

interface FeaturedImageProps {
  className?: string
  imageTitle: string
  imageUrl: string
  kvRatingClick?: () => void
  kvCta: string
  kvHeadline: string
  ratingsData: any
}

const FeaturedImage: React.FC<FeaturedImageProps> = ({
  className,
  imageTitle,
  imageUrl,
  kvRatingClick,
  ratingsData,
  kvCta,
  kvHeadline: kvCtaTitle,
}) => {
  const { t, i18n } = useTranslation('translation')
  const hasI18nInitialized = i18n.isInitialized

  const { kvImageUrl } = useMemo(
    () => ({
      kvImageUrl: imageUrl,
    }),
    [imageUrl],
  )

  const { showKVCta  } = useMemo(
    () => ({
      showKVCta: kvCta && kvCtaTitle,
    }),
    [kvCta],
  )

  return (
    <div className={`row featured-image ${className}`}>
      <div className="col-md-12">
        <ExternalImage src={kvImageUrl} title={imageTitle} />
        {hasI18nInitialized && ratingsData.showRatingContent && (
          <div className="featured-image__overlay">
            <Typography component="p" my={1} variant="h6">
              {ratingsData.featuredImageTitle}
            </Typography>
            <a
              className="rating-stars__ratings-link"
              title={ratingsData.customerFeedback}
            >
              <StarRating
                {...(ratingsData.rating as Rating)}
                description={ratingsData.ratingDescription}
                onClick={kvRatingClick}
              />
            </a>
            {showKVCta && (
              <CTAButton
                context="featured_image"
                link={kvCta}
                title={kvCtaTitle}
              />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default FeaturedImage

import clsx from 'clsx'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Review from '@pixum/review'
import Divider from '@pixum/divider'
import Pagination from '@pixum/pagination'
import StarRating, { BarRating } from '@pixum/rating'
import getReviewSchema from './helpers/getReviewSchema'
import { trackEvent } from '../../utils/ArtboxoneTracking'
import { ReviewProps, ReviewsProps } from './types'
import { fetchTSReviews } from './api/fetchTSReviews'
import TransformData from './helpers/transformData'
import formatDate from './helpers/formatDate'
import { filterReviewsBasedOnRating } from './helpers/filterReviewsBasedOnRating'
import '../../styles/reviews.css'
import getOverallRatings from './helpers/getOverallRatings'
import { isBrowser } from '../../utils/utils'

const CustomerReviews: React.FC<ReviewsProps> = ({
  ratings,
  reviews: reviewsContent,
  aggregateRatings,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [reviews, setReviews] = useState<ReviewProps[]>(reviewsContent)
  const [reviewsPerPage] = useState<number>(6)
  const [selectedRating, setSelectedRating] = useState<number>(5)
  const { t } = useTranslation('translation')

  const overallRatings = getOverallRatings(aggregateRatings)

  const maxRatingScale = 5
  const indexOfLastReview = currentPage * reviewsPerPage
  const indexOfFirstReview = indexOfLastReview - reviewsPerPage
  const idOfLastReview = isBrowser()
    ? reviews.at(indexOfLastReview - 1)?.id
    : ''
  const totalReviewsCount = ratings.amount
  const isFirstPage = currentPage === 1
  const isLastPage = currentPage === totalReviewsCount / reviewsPerPage
  const shouldGetMoreReviews = !isLastPage && reviews.length < indexOfLastReview
  const { currentReviews } = useMemo(
    () => ({
      currentReviews: reviews.slice(indexOfFirstReview, indexOfLastReview),
      overallRatings: overallRatings,
    }),
    [reviews, currentPage],
  )

  const schemaContent = {
    __html: `${JSON.stringify(currentReviews.map(getReviewSchema))}`,
  }

  const i18n: Record<string, string> = useMemo(
    () => ({
      feedbackHeadline: t('customer_feedback'),
      reviews: `(${ratings.amount} ${t('reviews')})`,
      authenticity: t('authenticity_reviews'),
      paginationText: t('pagination_text'),
      paginateNext: t('paginate_next'),
      paginatePrev: t('paginate_previous'),
      paginationFrom: t('pagination_from'),
      barRatingText: t('bar_rating_description'),
    }),
    [],
  )
  const currentPaginationText = `${i18n.paginationText} ${
    indexOfFirstReview === 0 ? 1 : indexOfFirstReview
  }-${indexOfLastReview} ${i18n.paginationFrom} ${totalReviewsCount}`
  const trackingCategory = 'customer-review'

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber)

  const handlePrevClick = () => {
    paginate(currentPage - 1)

    trackEvent({
      category: trackingCategory,
      action: 'paginate',
      label: 'previous',
    })
  }

  const handleNextClick = () => {
    paginate(currentPage + 1)

    trackEvent({
      category: trackingCategory,
      action: 'paginate',
      label: 'next',
    })
  }

  const handleRatingSelect = (selectedIndex: number) => {
    const filteredReviews = filterReviewsBasedOnRating(reviews, selectedIndex)

    if (reviews.length > 0) {
      setReviews([...filteredReviews, ...reviews])
      setSelectedRating(selectedIndex)
    }
  }

  const getMoreReviews = async () => {
    const before = idOfLastReview
    const result = await fetchTSReviews(before)
    const parsedResult = JSON.parse(result.response.data[0])
    const newReviews = TransformData(parsedResult.items)
    const moreReviews = [...reviews, ...newReviews]

    if (moreReviews.length > 0) {
      setReviews(moreReviews)
    }
  }

  useEffect(() => {
    if (shouldGetMoreReviews) {
      getMoreReviews()
    }
  }, [shouldGetMoreReviews])

  return (
    <div id="customerReviews">
      <h2>{i18n.feedbackHeadline}</h2>
      <div className="reviews">
        <div className="reviews__resume">
          <StarRating
            description={i18n.reviews}
            linked={false}
            value={ratings.value}
          />
          <div className="reviews__resume__ratings-bars">
            {overallRatings.length > 0 &&
              overallRatings.map((rating, index) => (
                <BarRating
                  className={clsx(
                    selectedRating === maxRatingScale - index &&
                      'rating__bar--selected',
                  )}
                  description={i18n.barRatingText}
                  key={`bar-rating-${index}`}
                  maxRatingScale={maxRatingScale}
                  onSelect={index => handleRatingSelect(index as number)}
                  ratingCount={rating}
                  ratingIndex={index}
                  totalRatings={totalReviewsCount}
                />
              ))}
          </div>
          <a
            href="/informationen/echtheit-kundenbewertungen"
            title={i18n.authenticity}
          >
            {i18n.authenticity}
          </a>
        </div>
        <div className="reviews__comments">
          {currentReviews.map((review, index: number) => (
            <Review
              comment={review.comment}
              date={formatDate(review.submittedAt)}
              key={`review-${index}`}
              rating={review.rating}
              title={review.title}
            />
          ))}
        </div>
      </div>
      <Divider blockMargin />
      <Pagination
        disableNextButton={isLastPage}
        disablePrevButton={isFirstPage}
        onNextClick={handleNextClick}
        onPrevClick={handlePrevClick}
        text={currentPaginationText}
        titleNextButton={i18n.paginateNext}
        titlePrevButton={i18n.paginatePrev}
      />
      <script
        dangerouslySetInnerHTML={schemaContent}
        type="application/ld+json"
      />
    </div>
  )
}

export default CustomerReviews

import React from 'react'
import { useProductsData } from '../../lib/products_hooks'
import ProductPreview from '../product/Preview'
import { useMaterialsData } from '../../lib/materials_hook'
import { ProductMaterial,
  ProductsOverviewOptions,
  ProductData,
  LoadMoreThanOneProductOptions,
} from '../../types'
import { isConnectedMaterial } from '../../utils/Mapping'
import { useTranslation } from 'react-i18next'
import { getPriceCountry, getCartCountry } from '../../utils/CountrySelection'

const ProductsOverview = (options: ProductsOverviewOptions) => {
  const { t } = useTranslation('translation')

  const priceCountry = getPriceCountry(getCartCountry())
  const materialsData = useMaterialsData().filter(
    (materialData: ProductMaterial) => {
      return isConnectedMaterial(materialData, options.material)
    },
  )

  const materialsDataPrice = materialsData.filter(
    (materialData: ProductMaterial) => {
      return materialData.price[priceCountry] > 0
    },
  )

  let enabled = materialsData.length >= 0

  if (enabled) {
    if (materialsData.length > 0 && materialsDataPrice.length == 0) {
      enabled = false
    }
  }

  const productsDataOptions: LoadMoreThanOneProductOptions = {
    material: options.material,
    page: options.page,
    searchQuery: options.searchQuery,
    categories: options.categories,
    colors: options.colors,
    ratios: options.ratios,
    artists: options.artists,
    styles: options.styles,
    bundleId: options.bundleId,
    sortingOrder: options.sortingOrder,
  }

  const productsData = useProductsData(productsDataOptions)

  if (options.productCount === 0) {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 text-center">
            {t('Keine Produkte gefunden...')}
          </div>
        </div>
      </>
    )
  } else if (!enabled) {
    return (
      <>
        <div className="row">
          <div className="col-sm-12 text-center">
            {t(
              'Wir liefern dieses Material nicht in das ausgewählte Lieferland.',
            )}
          </div>
        </div>
      </>
    )
  } else if (productsData.length == 0) {
    const empty = null

    const rows = []

    for (let i = 0; i < 6; i++) {
      rows.push(
        <div className="col-6 col-sm-4 text-center" key={'empty_' + i}>
          <ProductPreview
            material={options.material}
            materials={materialsData}
            productData={empty}
          />
        </div>,
      )
    }

    return (
      <>
        <div className="row">{rows.map((row) => row)}</div>
      </>
    )
  } else {
    if (typeof productsData.error === 'string') {
      return <></>
    } else {
      return (
        <>
          <div className="row">
            {productsData.map((productData: ProductData, i) => (
              <div className="col-6 col-sm-4" key={i}>
                <ProductPreview
                  material={options.material}
                  materials={materialsData}
                  productData={productData}
                  variant={options.variant}
                />
              </div>
            ))}
          </div>
        </>
      )
    }
  }
}

export default ProductsOverview
